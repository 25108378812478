import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { ContentWrapper } from '../components/ContentWrapper';
import { Overhead } from '../components/Overhead';
import { ExternalLink } from '../components/ExternalLink';

export const VoluntaryWorkSection = () => {
  const data = useStaticQuery<Queries.VoluntaryWorkQuery>(query);
  const voluntaryProjects =
    data.allMarkdownRemark.nodes?.[0].frontmatter?.voluntaryWork;

  if (!voluntaryProjects?.length) return <></>;

  return (
    <section id="voluntary-work">
      <ContentWrapper className="my-[100px]">
        <Overhead className="text-gray-600 dark:text-gray-300">
          Voluntary Work
        </Overhead>
        <div className="flex flex-col md:flex-row justify-start items-start gap-4 md:gap-8 mt-8">
          {voluntaryProjects.map((project, key) => {
            return !!project?.project ? (
              <div
                key={key}
                className="w-[300px] bg-gray-100 dark:bg-gray-900 shadow-default rounded-md px-4 pt-2 pb-4"
              >
                {project.url ? (
                  <ExternalLink
                    href={project.url}
                    className="text-sm font-semibold text-gray-900 dark:text-gray-50"
                    data-show-hint
                  >
                    {project.project}
                  </ExternalLink>
                ) : (
                  <span className="text-sm font-semibold text-gray-900 dark:text-gray-50">
                    {project.project}
                  </span>
                )}
                <p className="text-gray-500 dark:text-gray-400 text-xs">
                  {project?.institution}
                </p>
              </div>
            ) : undefined;
          })}
        </div>
      </ContentWrapper>
    </section>
  );
};

const query = graphql`
  query VoluntaryWork {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/profile/" } }) {
      nodes {
        frontmatter {
          voluntaryWork {
            institution
            project
            url
          }
        }
      }
    }
  }
`;
