import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { ContentWrapper } from '../components/ContentWrapper';
import { Overhead } from '../components/Overhead';
import { GatsbyImage } from 'gatsby-plugin-image';

export const MembershipsSection = () => {
  const data = useStaticQuery<Queries.MembershipsQuery>(query);
  const memberships =
    data.allMarkdownRemark.nodes?.[0].frontmatter?.memberships;

  if (!memberships?.length) return <></>;

  return (
    <section id="memberships">
      <ContentWrapper className="my-[100px]">
        <Overhead className="text-gray-600 dark:text-gray-300">
          Professional Memberships
        </Overhead>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          {memberships.map((membership, key) => {
            const imageAlt = membership?.name ?? '';
            const imageClassName = 'rounded-md w-12 flex-shrink-0';

            const isSvg = membership?.logo?.extension === 'svg';
            const isValidSvg = isSvg && !!membership.logo.publicURL;
            const isValidImage = !isSvg && !!membership?.logo?.childImageSharp;

            return (
              <div
                key={key}
                className="flex flex-row justify-start items-center gap-4"
              >
                {isValidSvg ? (
                  <img
                    src={membership.logo.publicURL}
                    alt={imageAlt}
                    className={imageClassName}
                  />
                ) : undefined}
                {isValidImage ? (
                  <GatsbyImage
                    image={membership.logo.childImageSharp.gatsbyImageData}
                    alt={imageAlt}
                    className={imageClassName}
                  />
                ) : undefined}
                <div>
                  <span className="text-sm font-semibold text-gray-900 dark:text-gray-50">
                    {membership?.name}
                  </span>
                  <p className="text-gray-500 dark:text-gray-400 text-xs">
                    {membership?.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </ContentWrapper>
    </section>
  );
};

const query = graphql`
  query Memberships {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/profile/" } }) {
      nodes {
        frontmatter {
          memberships {
            description
            name
            logo {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
