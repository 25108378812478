import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { ContentWrapper } from '../components/ContentWrapper';
import { CurrentRoleCard } from '../components/Cards/CurrentRole';
import { SocialProfiles } from '../components/SocialProfiles';
import { Chip } from '../components/Chip';

export const AboutSection = () => {
  const data = useStaticQuery<Queries.AboutSectionQuery>(query);
  const details = data.allMarkdownRemark.nodes?.[0].frontmatter;
  const body = data.allMarkdownRemark.nodes?.[0].rawMarkdownBody;

  const MarkdownContent = !!body ? (
    <div dangerouslySetInnerHTML={{ __html: body }} />
  ) : undefined;

  const CurrentRole = !!details?.currentRole ? (
    <CurrentRoleCard details={details.currentRole} />
  ) : undefined;

  const Interests = !!details?.interests?.length
    ? details.interests.map((interest, key) => {
        return <Chip key={key}>{interest}</Chip>;
      })
    : undefined;

  return (
    <section id="about">
      <ContentWrapper className="my-[100px]">
        <h2 className="mb-8">What I Do</h2>
        <div className="flex flex-col md:flex-row justify-between items-start gap-8 md:gap-16">
          <div className="pt-6">
            {CurrentRole}
            <div className="my-9 flex flex-wrap gap-3">{Interests}</div>
            <SocialProfiles />
          </div>
          <div className="whitespace-pre-wrap">
            {MarkdownContent}
            <div className="mt-8">
              {/* <Link to="/blog" className="link" data-show-hint>
                Learn more about me
              </Link> */}
            </div>
          </div>
        </div>
      </ContentWrapper>
    </section>
  );
};

const query = graphql`
  query AboutSection {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/profile/" } }) {
      nodes {
        frontmatter {
          interests
          currentRole {
            companyLogo {
              publicURL
              extension
              childImageSharp {
                gatsbyImageData
              }
            }
            companyUrl
            title
          }
        }
        rawMarkdownBody
      }
    }
  }
`;
