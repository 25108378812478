import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { Email } from 'react-obfuscate-email';

import { ContentWrapper } from '../components/ContentWrapper';
import { SocialProfiles } from '../components/SocialProfiles';
import { Avatar } from '../components/Avatar';

export const ContactSection = () => {
  const data = useStaticQuery<Queries.ContactSectionQuery>(query);
  const details = data.allMarkdownRemark.nodes?.[0].frontmatter;

  return (
    <section id="contact">
      <ContentWrapper className="my-[100px]">
        <div className="grid grid-cols-1 md:grid-cols-7 gap-8 md:gap-16">
          <div className="col-start-1 col-end-1 md:col-end-4 flex flex-col md:flex-row gap-8">
            <Avatar className="w-[140px] h-[140px] md:w-[80px] md:h-[80px]" />
            <div>
              <span className="block font-mono font-bold tracking-tight text-2xl md:text-xl text-primary-900 dark:text-primary-50">
                {details?.name}
              </span>
              {details?.email ? (
                <Email
                  className="block font-normal text-gray-500 dark:text-gray-400 mb-6"
                  email={details?.email}
                />
              ) : undefined}
              <SocialProfiles profiles={['github', 'linkedin']} showLabel />
            </div>
          </div>
          {!!details?.gpcFingerprint ? (
            <div className="w-full bg-gray-100 dark:bg-gray-900 shadow-default rounded-md px-4 py-3 col-start-1 col-end-1 md:col-start-4 md:col-end-8">
              <p className="text-gray-500 dark:text-gray-400 text-sm">
                Send me an email by using my{' '}
                <u className="accent-underlining">GPG key</u>.<br />
                My fingerprint is:
              </p>
              <code className="font-mono text-xs text-gray-700 dark:text-gray-300">
                {details.gpcFingerprint}
              </code>
            </div>
          ) : undefined}
        </div>
      </ContentWrapper>
    </section>
  );
};

const query = graphql`
  query ContactSection {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/profile/" } }) {
      nodes {
        frontmatter {
          name
          email
          gpcFingerprint
        }
      }
    }
  }
`;
