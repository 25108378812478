import * as React from 'react';

import type { HeadFC } from 'gatsby';

import { SEO } from '../components/SEO';
import { HeroSection } from '../sections/Hero';
import { LatestPostsSection } from '../sections/LatestPosts';
import { AboutSection } from '../sections/About';
import { MembershipsSection } from '../sections/Memberships';
import { VoluntaryWorkSection } from '../sections/VoluntaryWork';
import { Divider } from '../components/Divider';
import { ContactSection } from '../sections/Contact';
import { Layout } from '../components/Layout';

const IndexPage = () => {
  return (
    <Layout header={{ hideLogo: true }}>
      <HeroSection />
      <LatestPostsSection />
      <AboutSection />
      <MembershipsSection />
      <VoluntaryWorkSection />
      <Divider className="my-[100px]" />
      <ContactSection />
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <SEO />;
