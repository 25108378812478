import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { Avatar } from '../components/Avatar';
import { Button } from '../components/Button';
import { ContentWrapper } from '../components/ContentWrapper';
import { SocialProfiles } from '../components/SocialProfiles';

export const HeroSection = () => {
  const data = useStaticQuery<Queries.HeroSectionQuery>(query);
  const { name, intro } = data.allMarkdownRemark.nodes[0].frontmatter ?? {};

  return (
    <section id="hero">
      <ContentWrapper className="flex flex-col-reverse justify-start items-start gap-4 md:flex-row md:justify-between md:items-center md:gap-8">
        <div className="my-4 md:my-12 max-w-sm font-medium text-gray-500 dark:text-gray-400">
          <span className="block">Greetings! I am</span>
          <h1 className="my-4">{name}</h1>
          <p className="text-gray-500 dark:text-gray-400">{intro}</p>
          <div className="my-10">
            <Button type="button" onAction={() => scrollTo('#about')}>
              Get To Know Me
            </Button>
          </div>
          <SocialProfiles />
        </div>
        <Avatar className="w-[180px] h-[180px] md:w-[260px] md:h-[260px]" />
      </ContentWrapper>
    </section>
  );
};

const query = graphql`
  query HeroSection {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/profile/" } }) {
      nodes {
        frontmatter {
          name
          intro
        }
      }
    }
  }
`;
