import React from 'react';

import { graphql, Link, useStaticQuery } from 'gatsby';

import { ContentWrapper } from '../components/ContentWrapper';
import { BlogPostCard } from '../components/Cards/BlogPost';
import { Overhead } from '../components/Overhead';
import { Slider } from '../components/Slider';

export const LatestPostsSection = () => {
  const data = useStaticQuery<Queries.LatestPostsQuery>(query);
  const posts = data.allMarkdownRemark.nodes;

  if (!posts.length) return null;

  return (
    <section id="latest-posts">
      <ContentWrapper className="mt-[100px] mb-[140px]">
        <Overhead className="text-gray-600 dark:text-gray-300 mb-4">
          Recent Blog Posts
        </Overhead>
        <p className="text-gray-500 dark:text-gray-400 max-w-xl">
          Besides my{' '}
          <Link to="/publications" className="link" data-show-hint>
            publications
          </Link>
          , I write regularly on my blog about personal projects, hardware
          stuff, and computer security.
        </p>
        <Slider className="mt-8 mb-12 md:flex md:flex-row justify-start items-start gap-4">
          {posts.map((post, key) => {
            return <BlogPostCard details={post} key={key} />;
          })}
        </Slider>
        <Link to="/blog" className="link" data-show-hint>
          Discover all posts
        </Link>
      </ContentWrapper>
    </section>
  );
};

const query = graphql`
  query LatestPosts {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/posts/" } }
      limit: 3
    ) {
      nodes {
        ...BlogPostFrontmatter
      }
    }
  }
`;
