import React from 'react';

import { cx } from '../utils/cx';

/**
 * Children need to have the following classes:
 * inline-block whitespace-normal mr-6 md:mr-6
 */

export const Slider = (props: {
  children: React.ReactElement[];
  className?: string;
}) => {
  return (
    <div
      className={cx(
        'whitespace-nowrap overflow-x-auto -mx-6 px-6',
        props.className ?? ''
      )}
    >
      {props.children}
    </div>
  );
};
