import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { Email } from 'react-obfuscate-email';

import { ExternalLink } from './ExternalLink';
import { GithubSVG, LinkedInSVG } from './Icons';

const SocialPlatforms = {
  github: {
    label: 'Github',
    icon: GithubSVG,
  },
  linkedin: {
    label: 'LinkedIn',
    icon: LinkedInSVG,
  },
};

type SocialPlatform = keyof typeof SocialPlatforms;

type SocialProfilesProps = {
  showLabel?: boolean;
  /** If not specified, all profiles are shown. */
  profiles?: (SocialPlatform | 'email')[];
};

export const SocialProfiles = (props: SocialProfilesProps) => {
  const data = useStaticQuery<Queries.SocialProfilesQuery>(query);
  const email = data.allMarkdownRemark.nodes?.[0].frontmatter?.email;
  const profiles = data.allMarkdownRemark.nodes?.[0].frontmatter?.profiles;

  const Profiles = profiles?.map((profile, key) => {
    if (!profile?.name || !profile.url)
      throw new Error('Incomplete Social Profile');

    const platform = profile.name as SocialPlatform;

    const config = SocialPlatforms[platform];
    if (!config) throw new Error('Unknown Social Platform Provided');

    if (!!props.profiles?.length && !props.profiles.includes(platform))
      return <></>;

    const Icon = React.cloneElement(config.icon, {
      className: `${config.icon.props.className} w-5 h-5 link-button`,
    });

    const Label = props.showLabel ? (
      <span className="link text-sm font-normal">{config.label}</span>
    ) : (
      <></>
    );

    return (
      <ExternalLink
        key={key}
        href={profile.url}
        title={config.label}
        className="flex gap-2"
      >
        {Icon}
        {Label}
      </ExternalLink>
    );
  });

  const EmailLink =
    !!email &&
    (!props.profiles || props.profiles.includes(email as 'email')) ? (
      <Email email={email} className="link font-normal text-xs" />
    ) : undefined;

  return (
    <div className="flex items-center gap-4">
      {Profiles}
      {EmailLink}
    </div>
  );
};

const query = graphql`
  query SocialProfiles {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/profile/" } }) {
      nodes {
        frontmatter {
          email
          profiles {
            name
            url
          }
        }
      }
    }
  }
`;
